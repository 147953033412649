import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { CreateDistributionForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getRouteList } from '../../../routes/routes.tsx'
import { Button, LoadingButton } from 'aptranet-ui/components/ui/button.tsx'

const CreateDistribution = () => {
	const navigate = useNavigate()
	const createDistributionRef = useRef<HTMLFormElement>(null)

	const [createDistributionLoading, setCreateDistributionLoading] = useState<boolean>(false)

	return (
		<Content title="Create Distribution" subtitle="Create a new Distribution.">
			<CreateDistributionForm
				ref={createDistributionRef}
				setCreateDistributionLoading={setCreateDistributionLoading}
			/>
			<div className="flex justify-end mt-5">
				<Button
					size="sm"
					variant="outline"
					className="me-2"
					onClick={() => navigate(getRouteList().cdn.distributions.path)}
					disabled={createDistributionLoading}
				>
					Cancel
				</Button>
				<LoadingButton size="sm" text="Create Distribution" loading={createDistributionLoading}
											 onClick={() => createDistributionRef.current?.requestSubmit()} />
			</div>
		</Content>
	)
}

export default CreateDistribution
