import { Link, useLocation } from 'react-router-dom'

import { useUser } from '@clerk/clerk-react'
import { getFeedback, setUser } from '@sentry/react'

import { AptranetThemeModeToggle } from 'aptranet-ui/components/theme/mode-toggle.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'aptranet-ui/components/ui/tooltip'

import {
	BugIcon,
	CreditCardIcon,
	GaugeIcon,
	HomeIcon,
	ShieldIcon,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'

const AppBar = () => {
	const { user } = useUser()
	const { pathname } = useLocation()

	const [feedback, setFeedback] = useState()
	// Read `getFeedback` on the client only, to avoid hydration errors during server rendering
	useEffect(() => {
		// @ts-ignore
		setFeedback(getFeedback())
	}, [])

	const feedbackButtonRef = useRef()
	useEffect(() => {
		if (feedback) {
			setUser({
				username: user!.fullName || '',
				email: user!.emailAddresses[0].emailAddress,
			})

			// @ts-ignore
			return feedback.attachTo(feedbackButtonRef.current)
		}
		return () => {
		}
	}, [feedback])

	return (
		<aside className="hidden md:flex inset-y fixed left-0 z-20 h-full flex-col border-r">
			<div className="border-b p-2">
				<Button aria-label="Home" size="icon" variant="ghost" asChild>
					<Link to="/projects">
						<img src="https://assets.aptranetedge.com/logos/favicon-background-circle.svg" alt="Aptranet Icon"
								 className="size-7" />
					</Link>
				</Button>
			</div>
			<nav className="grid gap-1 p-2">
				<TooltipProvider delayDuration={0}>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								aria-label="Home"
								className={
									'rounded-lg' +
									({
										'/': ' bg-muted',
										'/quotas': ' bg-muted',
										'/settings': ' bg-muted',
										'/developers': ' bg-muted',
										'/climate': ' bg-muted',
									}[pathname] || pathname.startsWith('/developers')
										? ' bg-muted'
										: pathname.startsWith('/members')
											? ' bg-muted'
											: '')
								}
								size="icon"
								variant="ghost"
								asChild
							>
								<Link to="/">
									<HomeIcon className="size-5" />
								</Link>
							</Button>
						</TooltipTrigger>
						<TooltipContent side="right" sideOffset={5}>
							Home
						</TooltipContent>
					</Tooltip>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button aria-label="CDN" className={'rounded-lg' + (pathname.startsWith('/cdn') ? ' bg-muted' : '')}
											size="icon" variant="ghost" asChild>
								<Link to="/cdn">
									<GaugeIcon className="size-5" />
								</Link>
							</Button>
						</TooltipTrigger>
						<TooltipContent side="right" sideOffset={5}>
							CDN
						</TooltipContent>
					</Tooltip>
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="Storage"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/storage') ? ' bg-muted' : '')}*/}
					{/*						size="icon" variant="ghost" asChild>*/}
					{/*			<Link to="/storage"><HardDriveIcon className="size-5" /></Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		Storage*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="App Platform"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/app-platform') ? ' bg-muted' : '')}*/}
					{/*						size="icon" variant="ghost" asChild>*/}
					{/*			<Link to="/app-platform"><AppWindowIcon className="size-5" /></Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		App Platform*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="Compute"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/compute') ? ' bg-muted' : '')}*/}
					{/*						size="icon" variant="ghost" asChild>*/}
					{/*			<Link to="/compute"><CpuIcon className="size-5" /></Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		Compute*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="IntelliEdge"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/intelliedge') ? ' bg-muted' : '')} size="icon"*/}
					{/*						variant="ghost" asChild>*/}
					{/*			<Link to="/compute"><ZapIcon className="size-5" /></Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		IntelliEdge*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="API Gateway"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/api-gateway') ? ' bg-muted' : '')} size="icon"*/}
					{/*						variant="ghost" asChild>*/}
					{/*			<Link to="/api-gateway"><DiamondIcon className="size-5" /></Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		API Gateway*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="DNS" className={'rounded-lg' + (pathname.startsWith('/dns') ? ' bg-muted' : '')}*/}
					{/*						size="icon" variant="ghost" asChild>*/}
					{/*			<Link to="/dns"><GlobeIcon className="size-5" /></Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		DNS*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="Stream"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/stream') ? ' bg-muted' : '')} size="icon"*/}
					{/*						variant="ghost" asChild>*/}
					{/*			<Link to="/streaming"><PlayIcon className="size-5" /></Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		Stream*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					<Tooltip>
						<TooltipTrigger asChild>
							<Button aria-label="Shield" className={'rounded-lg' + (pathname.startsWith('/shield') ? ' bg-muted' : '')}
											size="icon" variant="ghost" asChild>
								<Link to="/shield">
									<ShieldIcon className="size-5" />
								</Link>
							</Button>
						</TooltipTrigger>
						<TooltipContent side="right" sideOffset={5}>
							Shield
						</TooltipContent>
					</Tooltip>
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button*/}
					{/*			aria-label="TLS Manager"*/}
					{/*			className={'rounded-lg' + (pathname.startsWith('/tls-manager') ? ' bg-muted' : '')}*/}
					{/*			size="icon"*/}
					{/*			variant="ghost"*/}
					{/*			asChild*/}
					{/*		>*/}
					{/*			<Link to="/tls-manager">*/}
					{/*				<LockIcon className="size-5" />*/}
					{/*			</Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		TLS Manager*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="Identity"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/identity') ? ' bg-muted' : '')} size="icon"*/}
					{/*						variant="ghost" asChild>*/}
					{/*			<Link to="/identity">*/}
					{/*				<IdCardIcon className="size-5" />*/}
					{/*			</Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		Identity*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<Button aria-label="Logging"*/}
					{/*						className={'rounded-lg' + (pathname.startsWith('/logging') ? ' bg-muted' : '')} size="icon"*/}
					{/*						variant="ghost" asChild>*/}
					{/*			<Link to="/logging">*/}
					{/*				<ListTreeIcon className="size-5" />*/}
					{/*			</Link>*/}
					{/*		</Button>*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		Logging*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
					<Tooltip>
						<TooltipTrigger asChild>
							<Button aria-label="Billing"
											className={'rounded-lg' + (pathname.startsWith('/billing') ? ' bg-muted' : '')} size="icon"
											variant="ghost" asChild>
								<Link to="/billing">
									<CreditCardIcon className="size-5" />
								</Link>
							</Button>
						</TooltipTrigger>
						<TooltipContent side="right" sideOffset={5}>
							Billing
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</nav>
			<nav className="mt-auto grid gap-1 p-2">
				<AptranetThemeModeToggle />
				<TooltipProvider delayDuration={0}>
					<Tooltip>
						<TooltipTrigger asChild>
							<Button
								aria-label="Feedback"
								className="mt-auto rounded-lg"
								size="icon"
								variant="ghost"
								/*@ts-ignore*/
								ref={feedbackButtonRef}
							>
								<BugIcon className="size-5" />
							</Button>
						</TooltipTrigger>
						<TooltipContent side="right" sideOffset={5}>
							Feedback
						</TooltipContent>
					</Tooltip>
					{/*<Tooltip>*/}
					{/*	<TooltipTrigger asChild>*/}
					{/*		<AptranetRoadmapButton />*/}
					{/*	</TooltipTrigger>*/}
					{/*	<TooltipContent side="right" sideOffset={5}>*/}
					{/*		Roadmap*/}
					{/*	</TooltipContent>*/}
					{/*</Tooltip>*/}
				</TooltipProvider>
			</nav>
		</aside>
	)
}

export default AppBar
