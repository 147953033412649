import { type Dispatch, type SetStateAction } from 'react'
import {
	aptranetQuotasTableColumns,
	type Quota,
} from 'aptranet-ui/components/management-console/home/quotas/quotas-table/columns.tsx'

import { type RowSelectionState } from '@tanstack/react-table'
import { DataTable } from 'aptranet-ui/components/ui/data-table.tsx'
import { Quota as APIQuota, Quotas } from '../../../../../../api/types/central.ts'

interface QuotasTableProps {
	service: 'cdn' | 'storage' | 'app_platform' | 'api_gateway' | 'compute' | 'intelliedge' | 'dns' | 'stream' | 'shield' | 'tls_manager' | 'logging' | 'developers',
	quotas: Quotas,
	rowSelection?: RowSelectionState,
	setRowSelection?: Dispatch<SetStateAction<object>>
}

const QuotasTable = (props: QuotasTableProps) => {
	const quotas: Quota[] = Object.keys(props.quotas[props.service]).map(quota_id => {
		const currentQuota: APIQuota = props.quotas[props.service][quota_id]

		return {
			id: quota_id,
			name: quota_id.split('_').map(word =>
				word
					.charAt(0)
					.toUpperCase() + word.slice(1))
				.join(' ')
				.replace('Cdn', 'CDN')
				.replace('Dns', 'DNS')
				.replace('Rrset', 'RRSet')
				.replace('Ttl', 'TTL')
				.replace('Api', 'API'),
			value: currentQuota.value,
			default_value: currentQuota.default_value,
			adjustable: currentQuota.adjustable,
			// utilization: 20,
		}
	})


	return <DataTable columns={aptranetQuotasTableColumns} data={quotas} searchBy="name" searchByTitle="Quota Name"
										defaultShown={{
											unit: false,
										}} enableRowSelection={(row) => row.getValue('adjustable')} rowSelection={props.rowSelection}
										setRowSelection={props.setRowSelection} />
}

export default QuotasTable