import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from 'aptranet-ui/components/ui/table.tsx'
import { Button } from 'aptranet-ui/components/ui/button.tsx'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'
import { PlusIcon, TrashIcon } from 'lucide-react'
import { Input } from 'aptranet-ui/components/ui/input.tsx'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { toast } from 'sonner'

interface CustomHostnamesFormProps {
	root_hostname: string,
	hostnames: CustomHostname[],
	addHostnameAction: (customHostname: string) => void,
	deleteHostnameAction: (customHostnameIndex: number) => void,
}

export interface CustomHostname {
	hostname: string,
	status: "active" | "inactive" | "provisioning" | "draft"
	// TODO:
	//  tls_manager_certificate_id: number
}

const formSchema = z.object({
	new_hostname: z.string(),
})

const CustomHostnamesForm = (props: CustomHostnamesFormProps) => {
	const { handleSubmit, control } = useForm()

	const onSubmit = (values: z.infer<typeof formSchema>) => {
		if (!props.hostnames.some(e => e.hostname === values.new_hostname)) {
			props.addHostnameAction(values.new_hostname)
		} else {
			toast.error('Hostname is already in use.')
		}
	}

	// @ts-ignore
	return <Table>
		<TableHeader>
			<TableRow>
				<TableHead>Hostname</TableHead>
				<TableHead>Status</TableHead>
				<TableHead className="text-right">Actions</TableHead>
			</TableRow>
		</TableHeader>
		<TableBody>
			<TableRow key={props.root_hostname}>
				<TableCell className="font-medium align-middle"><Button variant="link"
																																className="p-0 h-0">https://{props.root_hostname}</Button></TableCell>
				<TableCell colSpan={2}><Badge className="align-middle me-1.5" variant="primary">Root</Badge><Badge
					className="align-middle"
					variant="success">Available</Badge></TableCell>
			</TableRow>
			{props.hostnames.map(hostname => (
				<TableRow key={hostname.hostname}>
					<TableCell className="font-medium align-middle"><Button variant="link"
																																	className="p-0 h-0">https://{hostname.hostname}</Button></TableCell>
					<TableCell>{hostname.status === "draft" ? <Badge className="align-middle" variant="secondary">Draft</Badge> :
						<Badge className="align-middle" variant="success">Available</Badge>}</TableCell>
					<TableCell className="text-right align-top"><Button variant="ghost" className="py-0"
																															onClick={() => props.deleteHostnameAction(props.hostnames.indexOf(hostname))}><TrashIcon
						size={18} /></Button></TableCell>
				</TableRow>
			))
			}
		</TableBody>
		<TableFooter>
			<TableRow>
				<TableCell colSpan={3}>
					<div className="grid grid-cols-2 items-center space-x-2">
						<Controller
							control={control}
							name="new_hostname"
							rules={{ required: 'Hostname is required' }}
							render={({ field }) => (
								<Input placeholder="example.com" {...field} />
							)}
						/>
						{/*@ts-ignore*/}
						<Button size="sm" type="button" onClick={handleSubmit(onSubmit)}><PlusIcon /> Add
							Hostname</Button>
					</div>
				</TableCell>
			</TableRow>
		</TableFooter>
	</Table>
}

export default CustomHostnamesForm