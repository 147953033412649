import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import APIKeyCreatedModal from 'aptranet-ui/components/management-console/home/developers/api-keys/api-key-created-modal.tsx'
import APIKeysTable from 'aptranet-ui/components/management-console/home/developers/api-keys/api-keys-table/table.tsx'
import DeleteAPIKeyModal from 'aptranet-ui/components/management-console/home/developers/api-keys/delete-api-key-modal.tsx'
import RollSecretModal from 'aptranet-ui/components/management-console/home/developers/api-keys/roll-secret-modal.tsx'
import DevelopersOverviewSection from 'aptranet-ui/components/management-console/home/developers/overview/overview-section.tsx'
import { ActionButton } from 'aptranet-ui/components/ui/button.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'aptranet-ui/components/ui/tabs.tsx'

import { PlusIcon } from 'lucide-react'

import { listAPIKeys } from '../../../api/central/developers.tsx'
import { type APIKey } from '../../../api/types/central.ts'
import { getRouteList } from '../../../routes/routes.tsx'
import Webhooks from './webhooks.tsx'

const Developers = () => {
	const navigate = useNavigate()
	const routes = getRouteList()
	const { state }: { state: APIKey } = useLocation()

	const [selectedTab, setSelectedTab] = useState('api_keys') //TODO CHANGE THIS TO overview

	const [apiKeys, setAPIKeys] = useState<APIKey[] | null>(null)
	const [apiKeysRowSelection, setAPIKeysRowSelection] = useState({})

	const [apiKeyCreatedModalOpen, setAPIKeyCreatedModalOpen] = useState<boolean>(!!state)

	const [rollSecretOpen, setRollSecretOpen] = useState<boolean>(false)
	const [rollSecretModalData, setRollSecretModalData] = useState<APIKey>({
		access_key: '',
		created_at: '',
		id: 0,
		name: '',
		status: 0,
	})

	const [deleteAPIKeyModalOpen, setDeleteAPIKeyModalOpen] = useState<boolean>(false)
	const [deleteAPIKeyModalData, setDeleteAPIKeyModalData] = useState<APIKey>({
		access_key: '',
		created_at: '',
		id: 0,
		name: '',
		status: 0,
	})

	const openRollKeyModal = (apiKey: APIKey) => {
		setRollSecretModalData(apiKey)
		setRollSecretOpen(true)
	}

	const openDeleteAPIKeyModal = (apiKey: APIKey) => {
		setDeleteAPIKeyModalData(apiKey)
		setDeleteAPIKeyModalOpen(true)
	}

	useEffect(() => {
		if (selectedTab === 'api_keys') {
			listAPIKeys().then((apiKeys) => setAPIKeys(apiKeys))
		}
	}, [selectedTab])

	return (
		<Content
			title="Developers"
			subtitle="Your project developer settings."
			actionItems={
				selectedTab === 'api_keys' && (
					<ActionButton icon={PlusIcon} text="Create API Key" onClick={() => navigate(routes.home.developers_create_api_key.path)} />
				)
			}
		>
			<APIKeyCreatedModal open={apiKeyCreatedModalOpen} setOpen={setAPIKeyCreatedModalOpen} apiKeyData={state ?? {}} />
			<RollSecretModal open={rollSecretOpen} setOpen={setRollSecretOpen} apiKey={rollSecretModalData} />
			<DeleteAPIKeyModal open={deleteAPIKeyModalOpen} setOpen={setDeleteAPIKeyModalOpen} apiKey={deleteAPIKeyModalData} />
			<Tabs defaultValue="api_keys"> {/*TODO CHANGE THIS TO overview*/}
				<TabsList>
					{/*<TabsTrigger value="overview" onClick={() => setSelectedTab('overview')}>*/}
					{/*	Overview*/}
					{/*</TabsTrigger>*/}
					<TabsTrigger value="api_keys" onClick={() => setSelectedTab('api_keys')}>
						API Keys
					</TabsTrigger>
					{/*<TabsTrigger value="webhooks" onClick={() => setSelectedTab('webhooks')}>*/}
					{/*	Webhooks*/}
					{/*</TabsTrigger>*/}
					{/*<TabsTrigger value="logs" onClick={() => setSelectedTab('logs')}>*/}
					{/*	Logs*/}
					{/*</TabsTrigger>*/}
				</TabsList>
				<TabsContent value="overview">
					<DevelopersOverviewSection />
				</TabsContent>
				<TabsContent value="api_keys">
					{apiKeys ? (
						<APIKeysTable
							apiKeys={apiKeys}
							rowSelection={apiKeysRowSelection}
							setRowSelection={setAPIKeysRowSelection}
							openRollKeyModal={openRollKeyModal}
							openDeleteAPIKeyModal={openDeleteAPIKeyModal}
						/>
					) : (
						<>
							<div className="flex justify-between mb-4">
								<Skeleton className="w-[358px] h-[40px]" />
								<Skeleton className="w-[112px] h-[40px]" />
							</div>

							<Skeleton className="h-72" />
						</>
					)}
				</TabsContent>
				<TabsContent value="webhooks">
					<Webhooks />
				</TabsContent>
				<TabsContent value="logs">
					<p>TODO: Aptranet Logging Component</p>
				</TabsContent>
			</Tabs>
		</Content>
	)
}

export default Developers
