import requestInterceptor from '../interceptor.tsx'

export const getIntercomSession = () => new Promise<string>((resolve, reject) =>
	requestInterceptor
		.get('/central/accounts/support/intercom_session', {
			params: {
				project: localStorage.getItem('selected_project_id'),
			},
		})
		.then((res) => resolve(res.data))
		.catch((err) => reject(err.response.data)),
)