import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'
import Layout from './layout/layout.tsx'
import Error404 from './pages/error/404.tsx'

import Login from './pages/auth/login.tsx'
import Register from './pages/auth/register.tsx'

import Homepage from './pages/home/homepage.tsx'
import Projects from './pages/projects.tsx'

import { getRouteList } from './routes/routes.tsx'

function App() {
	// @ts-ignore
	window.Chargebee.init({
		site: import.meta.env.VITE_CHARGEBEE_SITE,
		publishableKey: import.meta.env.VITE_CHARGEBEE_PUBLISHABLE_KEY
	});

	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Layout />} errorElement={<Error404 />}>
				<Route index element={<Homepage />} />
				<Route path="/projects" element={<Projects />} />

				{/* This is here for OrganizationSwitcher */}
				<Route path="/redirect/projects" element={<Navigate to="/projects" />} />

				{/* Home */}
				{Object.values(getRouteList().home).map(route => <Route path={route.path} element={route.element} />)}

				{/* CDN */}
				{Object.values(getRouteList().cdn).map(route => <Route path={route.path} element={route.element} />)}

				{/* Shield */}
				{Object.values(getRouteList().shield).map(route => <Route path={route.path} element={route.element} />)}

				{/* Billing */}
				{Object.values(getRouteList().billing).map(route => <Route path={route.path} element={route.element} />)}

				<Route path="/auth/login/*" element={<Login />} />
				<Route path="/auth/register/*" element={<Register />} />
			</Route>,
		),
	)

	return <RouterProvider router={router} />
}

export default App
