import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import UnsavedChangesPopup from 'aptranet-ui/components/management-console/generic/unsaved-changes-popup.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { CacheConfigurationForm } from 'aptranet-ui/forms/aptranet-forms.tsx'

import { getDistributionCacheConfiguration } from '../../../../api/cdn/distribution-configurations.tsx'
import { type CacheConfiguration } from '../../../../api/types/cdn.tsx'
import { Button, LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { Trash2Icon } from 'lucide-react'
import { ResponsiveDialog } from 'aptranet-ui/components/ui/responsive-dialog.tsx'
import { toast } from 'sonner'
import { purgeDistributionCache } from '../../../../api/cdn/distributions.tsx'

const CacheConfiguration = () => {
	const { distributionID } = useParams()
	const cacheConfigurationRef = useRef<HTMLFormElement>(null)

	const [cacheConfigurationData, setCacheConfigurationData] = useState<CacheConfiguration | null>(null)

	const [unsavedChangesShown, setUnsavedChangesShown] = useState<boolean>(false)
	const [updateCacheConfigurationLoading, setUpdateCacheConfigurationLoading] = useState<boolean>(false)
	const [resetForm, setResetForm] = useState<{ run: () => void }>({ run: () => undefined })

	const fetchCacheConfiguration = () => {
		setCacheConfigurationData(null)
		getDistributionCacheConfiguration(Number(distributionID)).then((cacheConfiguration) => setCacheConfigurationData(cacheConfiguration))
	}

	useEffect(() => {
		fetchCacheConfiguration()
	}, [])

	const [cachePurgeOpen, setCachePurgeOpen] = useState(false)
	const [cachePurgeLoading, setCachePurgeLoading] = useState(false)

	const doCachePurge = () => {
		setCachePurgeLoading(true)
		purgeDistributionCache(parseInt(distributionID!)).then(() => {
			setCachePurgeLoading(false)
			toast.success('Cache purge initiated. It may take up to 30 seconds for changes to be seen globally.')
			setCachePurgeOpen(false)
		}).catch(() => setCachePurgeLoading(false))
	}

	return (
		<>
			<ResponsiveDialog title="Purge Cache" open={cachePurgeOpen} setOpen={setCachePurgeOpen} closeButtonText="Cancel"
												closeButtonDisabled={cachePurgeLoading} footerChildren={
				<LoadingButton variant="destructive" text="Purge Cache" loading={cachePurgeLoading}
											 onClick={doCachePurge} />
			}>
				<p>Purging the CDN Distribution cache invalidates all cached assets, forcing the CDN to fetch fresh copies from
					your origin server. This can significantly increase load on your origin, especially with large amounts of
					content. A rate limit of one purge request per Distribution per minute applies.</p>
			</ResponsiveDialog>
			<Content title="Cache Configuration" subtitle="Manage your Distribution's Cache Configuration."
							 actionItems={<Button variant="secondary" onClick={() => setCachePurgeOpen(true)}><Trash2Icon size={16}
																																																						className="me-1" /> Purge
								 Cache</Button>}>
				{cacheConfigurationData ? (
					<>
						<CacheConfigurationForm
							ref={cacheConfigurationRef}
							distributionID={Number(distributionID)}
							cacheConfigurationData={cacheConfigurationData}
							refreshCacheConfigurationData={fetchCacheConfiguration}
							setResetForm={setResetForm}
							setUnsavedChangesShown={setUnsavedChangesShown}
							setUpdateCacheConfigurationLoading={setUpdateCacheConfigurationLoading}
						/>
						<UnsavedChangesPopup
							shown={unsavedChangesShown}
							saveAction={() => cacheConfigurationRef.current?.requestSubmit()}
							cancelAction={() => resetForm.run()}
							cancelButtonText="Discard Changes"
							saveButtonText="Update Cache Configuration"
							saveButtonLoading={updateCacheConfigurationLoading}
						/>
					</>
				) : (
					<div className="flex flex-col items-center gap-3">
						<Skeleton className="w-full max-w-4xl h-28" />
						<Skeleton className="w-full max-w-4xl h-28" />
						<Skeleton className="w-full max-w-4xl h-36" />
						<Skeleton className="w-full max-w-4xl h-36" />
					</div>
				)}
			</Content>
		</>
	)
}

export default CacheConfiguration
