import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import { Badge } from 'aptranet-ui/components/ui/badge.tsx'
import { Button, LoadingButton } from 'aptranet-ui/components/ui/button.tsx'
import { Card, CardHeader, CardTitle } from 'aptranet-ui/components/ui/card.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'

import { GaugeIcon, type LucideIcon } from 'lucide-react'

import { createSession, getActiveServices } from '../../api/central/billing.tsx'
import { services } from '../../api/services.tsx'
import { ActiveServices, AptranetService } from '../../api/types/central.ts'

interface ServiceProps {
	service: AptranetService
	serviceLoading: AptranetService | null
	setServiceLoading: Dispatch<SetStateAction<AptranetService | null>>
	icon: LucideIcon
	isActive: boolean
}

const createCheckoutSession = (service: AptranetService, setServiceLoading: Dispatch<SetStateAction<AptranetService | null>>) => {
	setServiceLoading(service)

	createSession(service)
		.then((billingSession) => {
			// @ts-ignore
			const cbInstance = window.Chargebee.getInstance()

			cbInstance.openCheckout({
				hostedPage: () =>
					new Promise((resolve) => {
						resolve({
							id: billingSession.id,
							url: billingSession.url,
							type: 'checkout_existing',
						})
					}),
				error: () => {
					setServiceLoading(null)
				},
				success: () => {
					setServiceLoading(null)
					// Optional
					// will be called when a successful checkout happens.
				},
				close: () => {
					setServiceLoading(null)
				},
			})
		})
		.catch(() => setServiceLoading(null))
}

const Service = (props: ServiceProps) => (
	<Card>
		<CardHeader>
			<div className="flex justify-between">
				<div className="flex items-center">
					<props.icon className="mt-0.5" size={20} />
					<CardTitle className="ms-1.5 text-xl">{services[props.service]}</CardTitle>
					{props.isActive ? (
						<Badge variant="success" className="mt-1 ms-2">
							Active
						</Badge>
					) : (
						<Badge variant="secondary" className="mt-1 ms-2">
							Not Active
						</Badge>
					)}
				</div>
				<div className="flex items-center">
					{props.isActive ? (
						<Button variant="secondary" disabled>
							Service Active
						</Button>
					) : (
						<LoadingButton
							text="Activate Service"
							variant="secondary"
							loading={props.serviceLoading === props.service}
							disabled={props.serviceLoading !== null}
							onClick={() => createCheckoutSession(props.service, props.setServiceLoading)}
						/>
					)}
				</div>
			</div>
		</CardHeader>
	</Card>
)

const Services = () => {
	const [activeServices, setActiveServices] = useState<ActiveServices | null>(null)
	const [serviceLoading, setServiceLoading] = useState<AptranetService | null>(null)

	useEffect(() => {
		getActiveServices().then((res) => setActiveServices(res))
	}, [])

	return (
		<Content title="Services" subtitle="Manage the Aptranet services that you use.">
			<div className="grid xl:grid-cols-2 gap-3">
				{activeServices ? (
					<>
						<Service service="cdn" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading} icon={GaugeIcon} isActive={activeServices.cdn} />
						{/*<Service service="storage" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading}*/}
						{/*				 icon={HardDriveIcon} isActive={activeServices.storage} />*/}
						{/*<Service service="static_sites" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading}*/}
						{/*				 icon={AppWindowIcon} isActive={activeServices.static_sites} />*/}
						{/*<Service service="api_gateway" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading}*/}
						{/*				 icon={DiamondIcon} isActive={activeServices.api_gateway} />*/}
						{/*<Service service="compute" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading}*/}
						{/*				 icon={CpuIcon} isActive={activeServices.compute} />*/}
						{/*<Service service="intelliedge" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading}*/}
						{/*				 icon={ZapIcon} isActive={activeServices.intelliedge} />*/}
						{/*<Service service="dns" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading} icon={GlobeIcon}*/}
						{/*				 isActive={activeServices.dns} />*/}
						{/*<Service service="streaming" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading}*/}
						{/*				 icon={PlayIcon} isActive={activeServices.streaming} />*/}




						{/*<Service*/}
						{/*	service="shield"*/}
						{/*	serviceLoading={serviceLoading}*/}
						{/*	setServiceLoading={setServiceLoading}*/}
						{/*	icon={ShieldIcon}*/}
						{/*	isActive={activeServices.shield}*/}
						{/*/>*/}




						{/*<Service*/}
						{/*	service="tls_manager"*/}
						{/*	serviceLoading={serviceLoading}*/}
						{/*	setServiceLoading={setServiceLoading}*/}
						{/*	icon={LockIcon}*/}
						{/*	isActive={activeServices.tls_manager}*/}
						{/*/>*/}
						{/*<Service service="logging" serviceLoading={serviceLoading} setServiceLoading={setServiceLoading}*/}
						{/*				 icon={ListTreeIcon} isActive={activeServices.logging} />*/}
					</>
				) : (
					<>
						<Skeleton className="w-full h-24" />
						{/*<Skeleton className="w-full h-24" />*/}
						<Skeleton className="w-full h-24" />
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
						{/*<Skeleton className="w-full h-24" />*/}
					</>
				)}
			</div>
		</Content>
	)
}

export default Services
