import { useEffect, useState } from 'react'

import Content from 'aptranet-ui/components/management-console/generic/content.tsx'
import QuotasTable from 'aptranet-ui/components/management-console/home/quotas/quotas-table/table.tsx'
// import { ActionButton } from 'aptranet-ui/components/ui/button.tsx'
import { Skeleton } from 'aptranet-ui/components/ui/skeleton.tsx'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'aptranet-ui/components/ui/tabs'

// import { PlusIcon } from 'lucide-react'

import { getQuotas } from '../../api/central/quotas.tsx'
import { type Quotas } from '../../api/types/central'

const Quotas = () => {
	const [quotas, setQuotas] = useState<Quotas | null>(null)
	const [rowSelection, setRowSelection] = useState({})

	useEffect(() => {
		setRowSelection({})
		getQuotas().then((quotas) => setQuotas(quotas))
	}, [])

	return (
		<Content
			title="Quotas"
			subtitle="Your project's quotas."
			// actionItems={
			// 	<ActionButton
			// 		icon={PlusIcon}
			// 		text="Request Quota Increase"
			// 		onClick={() => console.log('Create Quota Request')}
			// 		disabled={Object.keys(rowSelection).length === 0}
			// 	/>
			// }
		>
			{quotas ? (
				<Tabs defaultValue="cdn">
					<TabsList>
						<TabsTrigger value="cdn" onClick={() => setRowSelection({})}>
							CDN
						</TabsTrigger>
						{/*<TabsTrigger value="storage" onClick={() => setRowSelection({})}>Storage</TabsTrigger>*/}
						{/*<TabsTrigger value="app_platform" onClick={() => setRowSelection({})}>App Platform</TabsTrigger>*/}
						{/*<TabsTrigger value="api_gateway" onClick={() => setRowSelection({})}>API Gateway</TabsTrigger>*/}
						{/*<TabsTrigger value="compute" onClick={() => setRowSelection({})}>Compute</TabsTrigger>*/}
						{/*<TabsTrigger value="intelliedge" onClick={() => setRowSelection({})}>IntelliEdge</TabsTrigger>*/}
						{/*<TabsTrigger value="dns" onClick={() => setRowSelection({})}>DNS</TabsTrigger>*/}
						{/*<TabsTrigger value="stream" onClick={() => setRowSelection({})}>Stream</TabsTrigger>*/}
						<TabsTrigger value="shield" onClick={() => setRowSelection({})}>
							Shield
						</TabsTrigger>
						{/*<TabsTrigger value="tls_manager" onClick={() => setRowSelection({})}>*/}
						{/*	TLS Manager*/}
						{/*</TabsTrigger>*/}
						{/*<TabsTrigger value="logging" onClick={() => setRowSelection({})}>Logging</TabsTrigger>*/}
						<TabsTrigger value="developers" onClick={() => setRowSelection({})}>
							Developers
						</TabsTrigger>
					</TabsList>
					<TabsContent value="cdn">
						<QuotasTable service="cdn" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="storage">
						<QuotasTable service="storage" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="app_platform">
						<QuotasTable service="app_platform" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="api_gateway">
						<QuotasTable service="api_gateway" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="compute">
						<QuotasTable service="compute" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="intelliedge">
						<QuotasTable service="intelliedge" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="dns">
						<QuotasTable service="dns" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="stream">
						<QuotasTable service="stream" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="shield">
						<QuotasTable service="shield" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="tls_manager">
						<QuotasTable service="tls_manager" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="logging">
						<QuotasTable service="logging" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
					<TabsContent value="developers">
						<QuotasTable service="developers" quotas={quotas} rowSelection={rowSelection} setRowSelection={setRowSelection} />
					</TabsContent>
				</Tabs>
			) : (
				<>
					<Skeleton className="w-[250px] h-[30px]" />
					{/*<Skeleton className="w-[982px] h-[30px]" />*/}

					<div className="flex justify-between py-4">
						<Skeleton className="w-[380px] h-[40px] " />
						<Skeleton className="w-[112px] h-[40px]" />
					</div>

					<Skeleton className="h-72" />
				</>
			)}
		</Content>
	)
}

export default Quotas
